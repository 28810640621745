import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ErrorLogComponent } from './error-log.component';
import { AuthService } from '../../../auth/auth.service';
import { Network } from '@capacitor/network';
import { RouterExtension } from '../../../router/router.service';
import { Observable } from 'rxjs';
import { CustomRetryButton, ErrorDialogParams } from '../service/error-modal.service';
import { $localize } from '@angular/localize/init';
import { Location } from '@angular/common';

@Component({
    selector: 'error-component',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
    networkStatus: { connected: boolean } = { connected: true };
    private clickCounter = 0;
    private modal: HTMLIonModalElement;

    constructor(
        private readonly router: RouterExtension,
        private readonly modalCtrl: ModalController,
        private readonly authService: AuthService,
        private readonly location: Location,
    ) {}

    private _errorMessages: Error[] = [];

    displayMessage = $localize`:@@components.error.text:Missing translation`;
    showReloadBtn: boolean;
    allowDismiss: boolean;
    escapeAction: 'logout' | 'back' | 'dismiss' = 'logout';
    customReloadButton: CustomRetryButton;
    retryButtonLabel = $localize`:@@components.error.button.reload:Missing translation`;

    get errorMessages(): Error[] {
        return this._errorMessages;
    }

    @Input() set errorDialogParams$(errorDialogParams$: Observable<ErrorDialogParams>) {
        errorDialogParams$.subscribe((params) => {
            this.escapeAction = params.escapeAction ? params.escapeAction : 'logout';
            this._errorMessages.push(params.error);
            if (params.displayMessage) {
                this.displayMessage = params.displayMessage;
            }
            this.showReloadBtn = params.showRetryBtn;
            this.allowDismiss = params.allowDismiss;
            if (params.customRetryButton) {
                this.customReloadButton = params.customRetryButton;
                if (this.customReloadButton.label) this.retryButtonLabel = this.customReloadButton.label;
            }
        });
    }

    async ionViewWillEnter() {
        this.networkStatus = await Network.getStatus();
    }

    async openErrorLog() {
        // The ErrorLog is a hidden feature and should be displayed after 3 clicks
        if (this.clickCounter++ < 2) return;
        this.clickCounter = 0;
        this.modal = await this.modalCtrl.create({
            component: ErrorLogComponent,
            componentProps: { errorMessages: [...this._errorMessages] },
        });
        return this.modal.present();
    }

    async retry() {
        await this.modalCtrl.dismiss(null, 'retry');
        if (this.customReloadButton?.retryAction) {
            this.customReloadButton.retryAction();
        } else {
            await this.router.reloadPage();
        }
    }

    async logout() {
        return this.authService.logout();
    }

    async hide() {
        await this.modalCtrl.dismiss(null, 'hide');
    }

    async navigateBack() {
        await this.modalCtrl.dismiss(null, 'hide');
        this.location.back();
    }
}
