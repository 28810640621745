import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';
import { IonPopover } from '@ionic/angular';

@Component({
    selector: 'dwin-header',
    templateUrl: './dwin-header.component.html',
    styleUrls: ['./dwin-header.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class DwinHeaderComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() subtitle_h1 = '';
    @Input() backButton = false;
    @Input() customBackButton = false;
    @Input() closingX = false;
    @Input() isProjectInfoHeader = false;
    @Input() isProjectDetailHeader = false;
    @Input() origin: string;
    @Input() noBottomBorder = false;

    @Output() closeClicked = new EventEmitter();
    @Output() ellipsisClicked = new EventEmitter();
    @Output() customBackClicked = new EventEmitter();
    @ViewChild('popover', { static: true }) popover: IonPopover;
    isCancelProjectPopoverOpen = false;

    constructor(private location: Location) {}

    onBackClicked() {
        if (!this.backButton) return;
        this.location.back();
    }

    onCloseClicked() {
        if (!this.closingX) return;
        this.closeClicked.emit();
    }

    customSelectPlaceholder: string = '';
    onEllipsisClicked() {
        this.isCancelProjectPopoverOpen = false;
        this.ellipsisClicked.emit();
    }

    presentPopover(e: Event) {
        this.popover.event = e;
        this.isCancelProjectPopoverOpen = true;
    }

    onCustomBackClicked() {
        if (!this.customBackButton) return;
        this.customBackClicked.emit();
    }

    protected readonly length = length;
}
