// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cancel-button {
  display: flex;
  justify-content: flex-end;
  margin: var(--d-spacing-single) var(--d-spacing-double) var(--d-spacing-single) 0;
}

.input-content {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
}

#error-output-textarea {
  width: 100%;
  padding: 12px 20px;
  resize: none;
  border: 2px dashed var(--ion-color-primary);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: white;
  margin-right: var(--d-spacing-double);
  margin-left: var(--d-spacing-double);
  margin-bottom: var(--d-spacing-double);
}

ion-button {
  --border-color: white;
}

.error-log-footer {
  width: 100%;
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: right;
  align-items: flex-end;
  background: white;
  position: sticky;
  bottom: 0;
  overflow: hidden;
  flex-shrink: 0;
  padding: var(--d-spacing-single) var(--d-spacing-double);
  padding-bottom: var(--d-spacing-double);
}`, "",{"version":3,"sources":["webpack://./src/app/error/modal/components/error-log.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,iFAAA;AACJ;;AAEA;EACI,SAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,2CAAA;EACA,yCAAA;EACA,kBAAA;EACA,uBAAA;EACA,qCAAA;EACA,oCAAA;EACA,sCAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,WAAA;EACA,0CAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,iBAAA;EACA,gBAAA;EACA,SAAA;EACA,gBAAA;EACA,cAAA;EACA,wDAAA;EACA,uCAAA;AACJ","sourcesContent":[".cancel-button {\n    display: flex;\n    justify-content: flex-end;\n    margin: var(--d-spacing-single) var(--d-spacing-double) var(--d-spacing-single) 0;\n}\n\n.input-content {\n    margin: 0;\n    padding: 0;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n}\n\n#error-output-textarea {\n    width: 100%;\n    padding: 12px 20px;\n    resize: none;\n    border: 2px dashed var(--ion-color-primary);\n    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 8px;\n    background-color: white;\n    margin-right: var(--d-spacing-double);\n    margin-left: var(--d-spacing-double);\n    margin-bottom: var(--d-spacing-double);\n}\n\nion-button {\n    --border-color: white;\n}\n\n.error-log-footer {\n    width: 100%;\n    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);\n    display: flex;\n    justify-content: right;\n    align-items: flex-end;\n    background: white;\n    position: sticky;\n    bottom: 0;\n    overflow: hidden;\n    flex-shrink: 0;\n    padding: var(--d-spacing-single) var(--d-spacing-double);\n    padding-bottom: var(--d-spacing-double);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
