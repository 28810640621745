export enum DwinUiCategory {
    FEED = 'FEED',
    CHATBOT = 'CHATBOT',
    DWIN = 'DWIN',
    PROJECTS = 'PROJECTS',
    PROFILE = 'PROFILE',
    FEEDBACK = 'FEEDBACK',
    // GENERAL
    CONFORMITY = 'CONFORMITY',
    IMPRINT = 'IMPRINT',
    PRIVACY = 'PRIVACY',
    USER_AGREEMENT = 'USER_AGREEMENT',
    // ERROR
    ERROR = 'ERROR',
}
