import { BuddyMessageFeedback } from './buddy-message-feedback';

export type BuddyMessage = ResponseMessage | RequestMessage;

export class ResponseMessage {
    constructor(text?: string, messageId?: string, feedback?: BuddyMessageFeedback) {
        this.text = text;
        this.messageId = messageId;
        this.feedback = feedback;
    }

    text: string;
    messageType = 'response' as const;
    messageId?: string;
    feedback?: BuddyMessageFeedback;
}

export class RequestMessage {
    constructor(text: string) {
        this.text = text;
    }

    messageId?: string;
    text: string;
    messageType = 'request' as const;
}
