import {
    AfterViewInit,
    booleanAttribute,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { Marked, Renderer } from '@ts-stack/markdown';
import DOMPurify from 'dompurify';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'markdown',
    templateUrl: './markdown.component.html',
    styleUrls: ['./markdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MarkdownComponent implements AfterViewInit, OnChanges {
    markdownId = 'markdown' + uuidv4();
    @Input() markdownText: string;
    @Input({ transform: booleanAttribute }) noTopMargin = false;
    @Input({ transform: booleanAttribute }) noBottomMargin = false;
    @Input() fontStyle: 'p1' | 'p2' | 'p1-white' = 'p1';

    ngAfterViewInit() {
        class ParagraphRenderer extends Renderer {
            fontStyle: 'p1' | 'p2' | 'p1-white' = 'p1';

            constructor(fontStyle: 'p1' | 'p2' | 'p1-white') {
                super();
                this.fontStyle = fontStyle;
            }

            override paragraph(text: string): string {
                return `<p class='${this.fontStyle}'>${text}</p>\n`;
            }

            override listitem(text: string): string {
                return `<li class='${this.fontStyle}'>${text}</li>\n`;
            }

            override list(body: string, ordered?: boolean): string {
                if (ordered) {
                    return `<ol class='${this.fontStyle}'>\n${body}</ol>\n`;
                }
                return `<ul class='${this.fontStyle}'>\n${body}</ul>\n`;
            }
        }

        Marked.setOptions({
            renderer: new ParagraphRenderer(this.fontStyle),
            sanitize: true,
            tables: false,
            breaks: true,
            isNoP: false,
        });
        document.getElementById(this.markdownId).innerHTML = DOMPurify.sanitize(Marked.parse(this.markdownText));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['markdownText'] && !changes['markdownText'].firstChange) {
            document.getElementById(this.markdownId).innerHTML = DOMPurify.sanitize(Marked.parse(this.markdownText));
        }
    }
}
