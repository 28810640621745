import { Injectable } from '@angular/core';
import { $localize } from '@angular/localize/init';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ErrorNotifyService {
    private currentToast: HTMLIonToastElement | null = null;

    constructor(private toastController: ToastController) {}

    async showErrorToast(message = $localize`:@@components.errorNotify.defaultText:Missing translation`) {
        if (this.currentToast) {
            await this.currentToast.dismiss();
        }
        this.currentToast = await this.toastController.create({
            message,
            duration: 7000,
            position: 'top',
            cssClass: 'no-word-break',
        });
        this.currentToast.onDidDismiss().then(() => (this.currentToast = null));
        await this.currentToast.present();
    }
}
