import { APP_INITIALIZER, ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, TitleStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService } from './auth/auth.service';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { GraphQLModule } from './graphql.module';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { DwinToolbarComponent } from 'src/app/toolbar/dwin-toolbar.component';
import { DwinSideMenuComponent } from 'src/app/side-menu/dwin-side-menu.component';
import { I18nModule } from 'src/app/common/components/i18n/i18n.module';
import { GqlRequestInterceptor } from 'src/app/loading/gql-request-interceptor.service';
import { AuthModule } from 'src/app/auth/auth.module';
import { ErrorModalModule } from './error/modal/error-modal.module';
import { provideMatomo, withRouter, withRouterInterceptors } from 'ngx-matomo-client';
import { TrackingService } from './tracking/tracking.service';
import { environment } from 'src/environments/environment';
import { ProjectTitleStrategyService } from './projects/services/project-title-strategy.service';
import { ProjectNavService } from './projects/services/project-nav.service';
import { RouteInterceptor } from './tracking/route.interceptor';
import 'hammerjs';
import { NotificationsModule } from './notifications/notifications.module';
import { NetworkStatusService } from './services/network-status.service';
import { BuddyModule } from './chatbot/buddy/buddy.module';
import { GlobalErrorService } from './error/global-error.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function playerFactory() {
    return player;
}

function initApp(authService: AuthService, networkStatusService: NetworkStatusService): () => any {
    return async () => {
        await networkStatusService.initNetworkStatus();

        if (networkStatusService.isConnected()) {
            await authService.isAuthenticated(false, true);
        }
    };
}

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@NgModule({
    declarations: [AppComponent, DwinToolbarComponent, DwinSideMenuComponent],
    imports: [
        NotificationsModule,
        AuthModule.forRoot([{ provide: 'BeforeSessionChangeListener', useClass: TrackingService }]),
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({ mode: 'md' }),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        BuddyModule.forRoot(AuthService),
        GraphQLModule,
        LottieModule.forRoot({ player: playerFactory }),
        LottieCacheModule,
        ErrorModalModule,
    ],
    providers: [
        I18nModule.setLocale(),
        I18nModule.setLocaleId(),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AuthService, NetworkStatusService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: GqlRequestInterceptor,
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: GlobalErrorService,
        },
        { provide: DEFAULT_TIMEOUT, useValue: 20000 },
        InAppBrowser,
        provideMatomo(
            {
                trackerUrl: 'https://matomo.staging.distel-digital.de/',
                siteId: environment.production ? '2' : '1',
            },
            withRouter({
                exclude: [/\/loading/],
            }),
            withRouterInterceptors([RouteInterceptor]),
        ),
        ProjectNavService,
        { provide: TitleStrategy, useClass: ProjectTitleStrategyService },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
