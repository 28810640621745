import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BuddyMessage, ResponseMessage } from '../buddy-data-structures/buddy-message';

@Component({
    selector: 'buddy-messages',
    templateUrl: './buddy-messages.component.html',
    styleUrls: ['./buddy-messages.component.scss'],
})
export class BuddyMessagesComponent implements AfterViewInit {
    @Input({ required: true }) messages$: Observable<BuddyMessage[]>;
    @Input() suggestedQuestions$: Observable<string[]>;
    @Input({ required: true }) showPulsingDots$: Observable<boolean>;
    @Input() readonly: boolean = true;

    @Output() likeMessageChange = new EventEmitter<ResponseMessage>();
    @Output() dislikeMessageChange = new EventEmitter<ResponseMessage>();
    @Output() suggestedQuestionClicked = new EventEmitter<string>();

    @ViewChild('container', { static: false }) containerDiv: ElementRef<HTMLDivElement>;

    private autoscrollEnabled: boolean = true;
    private previousScrollPosition: number = 0;
    hasOverflow = false;
    selectedQuestionIndex: number = -1;

    likeMessage(message: ResponseMessage) {
        if (!this.readonly) this.likeMessageChange.emit(message);
    }

    dislikeMessage(message: ResponseMessage) {
        if (!this.readonly) this.dislikeMessageChange.emit(message);
    }

    ngAfterViewInit(): void {
        this.messages$.subscribe(() => {
            this.handleAutoScroll();
        });
        this.suggestedQuestions$.subscribe(() => {
            setTimeout(() => this.scrollToBottom(), 1000);
        });
        this.showPulsingDots$.subscribe((showPulsingDots) => {
            if (showPulsingDots) {
                this.autoscrollEnabled = true;
                this.handleAutoScroll();
                // scroll to the bottom again after the dots are added to the view
                setTimeout(() => this.scrollToBottom(), 100);
            } else if (this.autoscrollEnabled) {
                // needed, otherwise autoscroll will disable itself when the dots will be removed
                setTimeout(() => this.scrollToBottom(), 100);
            }
        });
        this.handleAutoScroll();
        this.containerDiv.nativeElement.parentElement.addEventListener('scroll', (event) => {
            this.toggleAutoscrollByScrolling(event as any);
        });
    }

    /**
     * This method detects if the user scrolls up and disables autoscrolling. Autoscrolling is enabled again if the user scrolls to the bottom.
     * @param $event the scroll event
     * @private
     */
    private toggleAutoscrollByScrolling($event: {
        target: { scrollTop: number; scrollHeight: number; clientHeight: number };
    }) {
        const scrollPosition = $event.target.scrollTop;
        const scrollHeight = $event.target.scrollHeight;
        const divHeight = $event.target.clientHeight;
        const nearBottom = scrollPosition + divHeight >= scrollHeight - 80;
        const scrolledUp = scrollPosition < this.previousScrollPosition;
        this.autoscrollEnabled = nearBottom && !scrolledUp;
        this.previousScrollPosition = scrollPosition;
    }

    private handleAutoScroll() {
        if (this.autoscrollEnabled) {
            this.scrollToBottom();
        }
    }

    private scrollToBottom() {
        if (this.containerDiv) {
            const divElement = this.containerDiv.nativeElement;
            const parentElement = divElement.parentElement;
            if (divElement.clientHeight < divElement.scrollHeight) {
                this.hasOverflow = true;
            }
            // Scroll the div to the bottom
            parentElement.scrollTo({
                top: parentElement.scrollHeight,
                behavior: 'smooth',
            });
            return true;
        }
        return false;
    }

    onClickBubble(question: string, index: number) {
        this.selectedQuestionIndex = index;
        setTimeout(() => {
            this.selectedQuestionIndex = -1;
            this.suggestedQuestionClicked.emit(question);
        }, 500);
    }
}
