import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';

@Injectable({
    providedIn: 'root',
})
export class NetworkStatusService {
    private networkStatus = { connected: false };

    async initNetworkStatus() {
        this.networkStatus = await Network.getStatus();
        Network.addListener('networkStatusChange', (status) => {
            this.networkStatus = status;
        });
    }

    isConnected(): boolean {
        return this.networkStatus.connected;
    }
}
