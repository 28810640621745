import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DataType, Reference, ReferenceType, StateValueInput } from 'src/app/graphql-generated';
import { ModalController } from '@ionic/angular';
import { StateValueExtension } from 'src/app/state/state-value';
import { ReferenceContentItem } from '../../../common/components/reference/reference-content/reference-content.component';
import { isExpired, isStateValueValueValid } from '../../utils/state-value.utils';
import { StateValueInputSaveModalComponent } from '../state-value-input-save-modal/state-value-input-save-modal.component';
import { DwinNumericStateValueInputComponent } from '../numeric-state-value-input/dwin-numeric-state-value-input.component';

@Component({
    selector: 'dwin-state-value-input',
    templateUrl: './dwin-state-value-input.component.html',
    styleUrls: ['./dwin-state-value-input.component.scss'],
})
export class DwinStateValueInputComponent {
    @ViewChild(DwinNumericStateValueInputComponent) numericStateValueComponent!: DwinNumericStateValueInputComponent;
    @Output() cancelled = new EventEmitter();
    @Output() results = new EventEmitter<StateValueInput[]>();

    @Input() text: string;

    private _skipKnownAndValid: boolean = false;

    get skipKnownAndValid(): boolean {
        return this._skipKnownAndValid;
    }

    @Input() set skipKnownAndValid(shouldSkip: boolean) {
        this._skipKnownAndValid = shouldSkip;
        if (this.stateValues) {
            this.stateValues = this._stateValues;
        }
    }

    private _stateValues: StateValueExtension[] = [];

    get stateValues(): StateValueExtension[] {
        return this._stateValues;
    }
    @Input()
    set stateValues(stateValues: StateValueExtension[]) {
        this._stateValues = stateValues.map((sv) => ({
            state: sv.state,
            value: sv.value,
            timestamp: sv.timestamp,
            question: sv.question,
            originalStateValue: sv.value,
            lifeTimeMs: sv.state.lifeTimeMs,
            expiresAt: sv.expiresAt,
        }));
        this.initStateValues(this.stateValues);
    }

    private _references: ReferenceContentItem[] = [];
    get references(): ReferenceContentItem[] {
        return this._references;
    }
    @Input()
    set references(references: ReferenceContentItem[]) {
        this._references = references;
        this.updateAggregatedReferences();
    }

    private timeoutId: any = null;
    index = 0;
    isValid = false;
    noStateValuesToQuery: boolean = false;
    progress = 0;
    aggregatedReferences: ReferenceContentItem[] = [];
    stateValueReferences: ReferenceContentItem[] = [];
    stateValueSelection: Map<string, StateValueInput> = new Map<string, StateValueInput>();

    DataType = DataType;
    protected readonly isStateValueValueValid = isStateValueValueValid;

    displayedStateValues: StateValueExtension[] = [];

    constructor(private modalCtrl: ModalController) {}

    finalizeTaskWithNoStateValuesToQuery(): void {
        this.dismissModal('confirm');
    }

    async discard() {
        this.cancelled.emit();
        if (this.index > 0) {
            await this.showSaveModal();
        } else {
            this.dismissModal('cancel');
        }
    }

    async confirm(isLast: boolean): Promise<void> {
        if (!this.isValid) return;
        if (isLast && this.isEveryStateValueSet()) {
            this.dismissModal('confirm');
        } else {
            this.advanceToNextState();
        }
    }

    async save(): Promise<void> {
        this.dismissModal('save');
    }

    setIsValid(isValid: boolean) {
        this.isValid = isValid;
    }

    delayedSelectValue(isLast: boolean) {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(() => {
            this.selectValue(isLast);
            this.timeoutId = null;
        }, 420);
    }

    selectValue(isLast: boolean): void {
        if (!this.isValid) return;
        this.stateValueSelection.set(this.displayedStateValues[this.index].state.id, {
            stateId: this.displayedStateValues[this.index].state.id,
            value: this.displayedStateValues[this.index].value,
        });
        this.confirm(isLast);
    }

    back() {
        if (this.index > 0) {
            this.index--;
            this.updateProgressBar();
        }
        this.updateAggregatedReferences();
    }

    private getFilteredStateValuesByValidityAndLifetime(stateValues: StateValueExtension[]): StateValueExtension[] {
        const hasValue = (sv: StateValueExtension) => {
            return !sv.value || sv.value.trim() !== '';
        };
        const hasUnlimitedLifetime = (sv: StateValueExtension) => {
            return !sv.state.lifeTimeMs;
        };

        if (stateValues.length > 1) {
            return stateValues.filter(
                (stateValue) =>
                    !isStateValueValueValid(stateValue) || !hasValue(stateValue) || !hasUnlimitedLifetime(stateValue),
            );
        } else {
            return stateValues;
        }
    }

    private initStateValues(stateValues: StateValueExtension[]) {
        for (const stateValue of stateValues) {
            if (isStateValueValueValid(stateValue)) {
                this.stateValueSelection.set(stateValue.state.id, {
                    stateId: stateValue.state.id,
                    value: stateValue.value,
                });
            }
        }
        this.displayedStateValues = this.getFilteredStateValuesByValidityAndLifetime(stateValues);
        this.noStateValuesToQuery = this.displayedStateValues?.length === 0;
        if (!this.noStateValuesToQuery) {
            this.noStateValuesToQuery = false;
            this.updateProgressBar();
            this.updateAggregatedReferences();
        }

        if (this.skipKnownAndValid) {
            this.jumpForward();
        }
    }

    private jumpForward() {
        const index = this.displayedStateValues.findIndex((sv) => {
            return !sv.originalStateValue || !isStateValueValueValid(sv) || isExpired(sv.expiresAt);
        });
        if (index >= 0) {
            this.index = index;
        }
        this.updateProgressBar();
    }

    private async showSaveModal() {
        const modal = await this.modalCtrl.create({
            component: StateValueInputSaveModalComponent,
            cssClass: 'mini-modal-bottom',
        });
        await modal.present();
        const selected = await modal.onDidDismiss<StateValueInput[]>();
        if (selected.role === 'save') {
            await this.save();
        } else if (selected.role === 'cancel') {
            this.dismissModal('cancel');
        }
    }

    private isEveryStateValueSet(): boolean {
        return this.stateValues.every((stateValue) => this.stateValueSelection.has(stateValue.state.id));
    }

    private updateAggregatedReferences() {
        if (this.index < this.displayedStateValues.length) {
            this.stateValueReferences =
                this.displayedStateValues[this.index].state.references &&
                this.displayedStateValues[this.index].state.references.length > 0
                    ? this.addTitleToReference(this.displayedStateValues[this.index].state.references)
                    : [];
            this.aggregatedReferences = [];
            if (this.stateValueReferences && this.stateValueReferences.length > 0) {
                this.aggregatedReferences.push(...this.stateValueReferences);
            }
            if (this.references && this.references.length > 0) {
                this.aggregatedReferences.push(...this.references);
            }
        }
    }

    private addTitleToReference(references: Reference[]): ReferenceContentItem[] {
        if (!references || references.length === 0) return [];
        return references.map((r) => {
            let title: string;
            switch (r.referenceType) {
                case ReferenceType.Hint:
                    title = $localize`:@@components.state-reference-title.hint:Missing translation`;
                    break;
                case ReferenceType.Disclaimer:
                    title = $localize`:@@components.state-reference-title.disclaimer:Missing translation`;
                    break;
                case ReferenceType.Source:
                    title = $localize`:@@components.state-reference-title.sources:Missing translation`;
                    break;
                default:
                    title = $localize`:@@components.state-reference-title.unknown:Missing translation`;
                    break;
            }
            return {
                title,
                referenceType: r.referenceType,
                __typename: 'Reference',
                description: r.description,
            } as ReferenceContentItem;
        });
    }

    private updateProgressBar() {
        if (this.displayedStateValues.length > 0) {
            this.progress = (this.index + 1) / this.displayedStateValues.length;
        } else {
            this.progress = 0;
        }
        this.resetInitialNumericStateValue();
    }

    private resetInitialNumericStateValue() {
        if (this.numericStateValueComponent) this.numericStateValueComponent.initialValue = null;
    }

    private advanceToNextState() {
        this.index++;
        this.updateProgressBar();
        this.updateAggregatedReferences();
    }

    private async dismissModal(role: 'save' | 'confirm' | 'cancel') {
        switch (role) {
            case 'cancel':
                await this.modalCtrl.dismiss(null, role);
                break;
            case 'confirm':
                this.results.emit([...this.stateValueSelection.values()]);
                await this.modalCtrl.dismiss([...this.stateValueSelection.values()], role);
                break;
            case 'save':
                await this.modalCtrl.dismiss([...this.stateValueSelection.values()], role);
                break;
        }
    }
}
