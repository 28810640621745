// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container ion-button:first-child {
  margin-left: 0;
}
.button-container ion-button:last-child {
  margin-right: 0;
}

.bottom-padding {
  padding-bottom: var(--d-spacing-double);
}

.offline-error-header {
  margin-top: var(--d-spacing-double);
  margin-bottom: var(--d-spacing-single);
  white-space: pre-wrap;
}

h1.h1 {
  margin-bottom: var(--d-spacing-single);
}

p.p1 {
  margin-top: 0;
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/error/modal/components/error.component.scss"],"names":[],"mappings":"AACI;EACI,cAAA;AAAR;AAGI;EACI,eAAA;AADR;;AAKA;EACI,uCAAA;AAFJ;;AAKA;EACI,mCAAA;EACA,sCAAA;EACA,qBAAA;AAFJ;;AAKA;EACI,sCAAA;AAFJ;;AAKA;EACI,aAAA;EACA,qBAAA;AAFJ","sourcesContent":[".button-container {\n    ion-button:first-child {\n        margin-left: 0;\n    }\n\n    ion-button:last-child {\n        margin-right: 0;\n    }\n}\n\n.bottom-padding {\n    padding-bottom: var(--d-spacing-double);\n}\n\n.offline-error-header {\n    margin-top: var(--d-spacing-double);\n    margin-bottom: var(--d-spacing-single);\n    white-space: pre-wrap;\n}\n\nh1.h1 {\n    margin-bottom: var(--d-spacing-single);\n}\n\np.p1 {\n    margin-top: 0;\n    white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
