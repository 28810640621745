import {
    DataType,
    StateNumeric,
    StateSelection,
    StateValue,
    StateValueFragmentFragment,
    UnknownAndKnownStateValuesFragment,
} from '../../graphql-generated';
import { StateValueExtension } from '../../state/state-value';
import { StateValueTableItem } from '../components/state-value-table/state-value-table.component';

export function removeSubscores(
    unknownAndKnownStateValues: UnknownAndKnownStateValuesFragment,
): UnknownAndKnownStateValuesFragment {
    const filteredKnownStateValues = unknownAndKnownStateValues.knownStateValues.filter((sv) => !sv.state.isSubscore);
    const filteredUnknownStateValues = unknownAndKnownStateValues.unknownStateValueStates.filter(
        (svs) => !svs.isSubscore,
    );

    return { knownStateValues: filteredKnownStateValues, unknownStateValueStates: filteredUnknownStateValues };
}

export function mapStateValueToTableItem(
    stateValue: StateValueFragmentFragment,
    value?: string,
    overwrittenDefaultQuestion?: string,
): StateValueTableItem {
    let question = overwrittenDefaultQuestion ?? stateValue.state.defaultQuestion;
    if (stateValue.state.dataType === DataType.Selection) {
        value = (stateValue.state as StateSelection).selectionOptions.find((so) => so.id === value).name;
    }
    const unit =
        stateValue.state.__typename === 'StateNumeric' && stateValue.state.unit?.id
            ? ' ' + (stateValue.state.unit?.displaynameDE ?? stateValue.state.unit.id)
            : '';
    return {
        stateId: stateValue.state.id,
        value: value + unit,
        question,
        dataType: stateValue.state.dataType,
    };
}

export function isStateValueValueValid(stateValue: StateValueExtension | StateValue): boolean {
    if (!stateValue?.value || stateValue.value === '') return false;
    if (stateValue.state.dataType === DataType.Selection) {
        const selectionOptions = (stateValue.state as StateSelection).selectionOptions.map((so) => so.id);
        return selectionOptions.includes(stateValue.value);
    } else if (stateValue.state.dataType === DataType.Numeric) {
        if ((stateValue.state as StateNumeric).valueRange) {
            return (
                Number(stateValue.value) >= (stateValue.state as StateNumeric).valueRange.min &&
                Number(stateValue.value) <= (stateValue.state as StateNumeric).valueRange.max
            );
        }
    }
    return true;
}

export function isExpired(expiresAtUnixTimestamp: number): boolean {
    if (!expiresAtUnixTimestamp) return false;
    const now = Math.floor(new Date().getTime() / 1000);
    return now > expiresAtUnixTimestamp;
}
