import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom, ReplaySubject } from 'rxjs';
import { BuddyMessage } from '../buddy-data-structures/buddy-message';
import { $localize } from '@angular/localize/init';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BuddyErrorModalService } from '../buddy-services/buddy-error-modal.service';

@Component({
    selector: 'buddy-history',
    templateUrl: 'buddy-history.component.html',
    styleUrls: ['./buddy-history.component.scss'],
})
export class BuddyHistoryComponent implements OnInit {
    @Input() footerText: string;
    @Input({ required: true }) chatId: string;
    @Input({ required: true }) accessToken: string;

    showPulsingDots$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    protected messages$: ReplaySubject<BuddyMessage[]> = new ReplaySubject<BuddyMessage[]>(1);
    protected suggestedQuestions$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private _messages: BuddyMessage[] = [];

    constructor(
        private http: HttpClient,
        private errorModalService: BuddyErrorModalService,
    ) {}

    addMessages(messages: BuddyMessage[]) {
        this._messages = this._messages.concat(messages);
        this.messages$.next(this._messages);
    }

    async ngOnInit() {
        if (!this.chatId) {
            console.error('BuddyHistoryComponent: ChatId is required');
        }
        this.showPulsingDots$.next(true);
        try {
            this.addMessages(await this.fetchChatHistory());
        } catch (error) {
            console.error(`Error fetching chat history for chatId ${this.chatId}`, error);
            let displayMessage = $localize`:@@components.chatbot-history.loading-failed:Missing translation`;
            if (error?.status === 404) {
                displayMessage = $localize`:@@components.chatbot-history.not-found:Missing translation`;
            }
            this.errorModalService.showErrorDialog({
                error,
                escapeAction: 'back',
                displayMessage,
            });
        } finally {
            this.showPulsingDots$.next(false);
        }
    }

    private async fetchChatHistory() {
        return firstValueFrom(
            this.http.get<BuddyMessage[]>(`${environment.CHATBOT_BACKEND}/connected/chats/${this.chatId}/messages`, {
                headers: {
                    Authorization: this.accessToken,
                },
                withCredentials: true,
            }),
        );
    }
}
