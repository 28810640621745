import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ErrorComponent } from 'src/app/error/modal/components/error.component';
import { ReplaySubject } from 'rxjs';

export interface BuddyErrorDialogParams {
    error: Error;
    showRetryBtn?: boolean;
    escapeAction?: 'logout' | 'back' | 'dismiss';
    displayMessage?: string;
    allowDismiss?: boolean;
    customRetryButton?: CustomRetryButton;
}

export interface CustomRetryButton {
    label?: string;
    retryAction?: () => void;
}

@Injectable({ providedIn: 'root' })
export class BuddyErrorModalService {
    private modal: HTMLIonModalElement | null = null;
    private errorDialogParams$ = new ReplaySubject<BuddyErrorDialogParams>();
    private isModalShown = false;

    constructor(private modalCtrl: ModalController) {}

    async showErrorDialog(params: BuddyErrorDialogParams) {
        params.showRetryBtn = params.showRetryBtn ?? true;
        params.allowDismiss = params.allowDismiss ?? false;
        this.errorDialogParams$.next(params);

        if (!this.modal && !this.isModalShown) {
            this.isModalShown = true;
            this.modal = await this.modalCtrl.create({
                id: 'ErrorComponent',
                component: ErrorComponent,
                componentProps: { errorDialogParams$: this.errorDialogParams$ },
                breakpoints: [0, 1],
                initialBreakpoint: 1,
                cssClass: 'mini-modal',
                backdropDismiss: !params.allowDismiss,
                canDismiss: async (_data, role) => params.allowDismiss || ['hide', 'retry'].includes(role),
            });

            this.modal.onDidDismiss().then(() => {
                this.isModalShown = false;
                this.modal = null;
                this.errorDialogParams$ = new ReplaySubject<BuddyErrorDialogParams>();
            });

            await this.modal.present();
        }
    }

    async hide() {
        await this.modal?.dismiss(null, 'hide');
    }
}
