import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Location } from '@angular/common';

@Component({
    selector: 'dwin-header',
    templateUrl: './dwin-header.component.html',
    styleUrls: ['./dwin-header.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class DwinHeaderComponent {
    @Input() title = '';
    @Input() subtitle = '';
    @Input() subtitle_h1 = '';
    @Input() backButton = false;
    @Input() customBackButton = false;
    @Input() closingX = false;
    @Input() isProjectInfoHeader = false;
    @Input() isProjectDetailHeader = false;
    @Input() origin: string;

    @Output() closeClicked = new EventEmitter();
    @Output() customBackClicked = new EventEmitter();

    constructor(private location: Location) {}

    onBackClicked() {
        if (!this.backButton) return;
        this.location.back();
    }

    onCloseClicked() {
        if (!this.closingX) return;
        this.closeClicked.emit();
    }

    onCustomBackClicked() {
        if (!this.customBackButton) return;
        this.customBackClicked.emit();
    }

    protected readonly length = length;
}
