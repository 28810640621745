import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Notification, NotificationType } from '../model/notifications';
import { NotificationsService } from '../service/notifications.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    @Input() notification: Notification;
    @Input() notificationsService: NotificationsService;

    constructor(
        private readonly modalController: ModalController,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        console.log('notification', this.notification);
    }

    close() {
        this.modalController.dismiss();
    }

    protected readonly NotificationType = NotificationType;

    getStoreUrl() {
        const plattform = this.notificationsService.getPlatform();
        const playStoreUrl =
            this.notification.variables?.playStoreUrl ?? 'https://play.google.com/store/apps/details?id=health.dwin';
        const appStoreUrl =
            this.notification.variables?.appStoreUrl ??
            'https://apps.apple.com/de/app/dwin/id6535683545?platform=iphone';
        switch (plattform) {
            case 'android':
                return playStoreUrl;
            case 'ios':
                return appStoreUrl;
            default:
                return playStoreUrl;
        }
    }

    openStoreUrl() {
        const storeUrl = this.getStoreUrl();
        window.open(storeUrl, '_blank');
    }

    openPrivacyPage() {
        window.open(`https://storage.dwin.health/dwin-pflichtangabe-seiten/privacy.html`, '_blank');
    }

    openUserAgreementPage() {
        window.open(`https://storage.dwin.health/dwin-pflichtangabe-seiten/imprint.html`, '_blank');
    }
}
