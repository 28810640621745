import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DwinBackButtonComponent } from './back-button/dwin-back-button.component';
import { DwinLabelValueComponent } from './label-value/dwin-label-value.component';
import { DwinHeaderComponent } from './dwin-header/dwin-header.component';
import { DwinTitleUnderlinedComponent } from './title-underlined/dwin-title-underlined.component';
import { DwinDescriptionBlockComponent } from './description-block/dwin-description-block.component';
import { DwinSimpleCardComponent } from 'src/app/common/components/simple-card/dwin-simple-card.component';
import { RangeWithIntervalsComponent } from 'src/app/common/components/range-with-intervals/range-with-intervals.component';
import { ReferenceContentComponent } from './reference/reference-content/reference-content.component';
import { ReferenceModalComponent } from './reference/reference-modal/reference-modal.component';
import { ReferenceBarComponent } from './reference/reference-bar/reference-bar.component';
import { MediaWrapperComponent } from './media-wrapper/media-wrapper.component';
import { LottieModule } from 'ngx-lottie';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { MarkdownModule } from '../../markdown/markdown.module';

@NgModule({
    declarations: [
        DwinBackButtonComponent,
        DwinLabelValueComponent,
        DwinHeaderComponent,
        DwinTitleUnderlinedComponent,
        DwinDescriptionBlockComponent,
        DwinSimpleCardComponent,
        RangeWithIntervalsComponent,
        ReferenceContentComponent,
        ReferenceModalComponent,
        ReferenceBarComponent,
        MediaWrapperComponent,
        DateFormatPipe,
    ],
    imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule, LottieModule, MarkdownModule],
    exports: [
        DwinBackButtonComponent,
        DwinLabelValueComponent,
        DwinHeaderComponent,
        DwinTitleUnderlinedComponent,
        DwinDescriptionBlockComponent,
        DwinSimpleCardComponent,
        RangeWithIntervalsComponent,
        ReferenceContentComponent,
        ReferenceBarComponent,
        MediaWrapperComponent,
        DateFormatPipe,
    ],
})
export class DwinCommonComponentsModule {}
