// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-modal-grid {
  padding: 0;
  height: 100%;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.notification-modal-cancel {
  padding-top: var(--d-spacing-single);
  padding-bottom: var(--d-spacing-single);
  padding-right: calc(var(--d-spacing-single) + var(--d-spacing-half));
  display: flex;
  justify-content: flex-end;
}

.notification-modal-content {
  display: flex;
  flex-flow: column;
  border: 2px dashed var(--ion-color-primary);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: var(--ion-color-primary);
  padding: var(--d-spacing-double) var(--d-spacing-double) 0 var(--d-spacing-double);
  margin: var(--d-spacing-single) var(--d-spacing-double);
  overflow-y: scroll;
  overflow-x: hidden;
}

.notification-modal-bottom-divider {
  margin: 0;
  padding: 0;
  z-index: 2;
  width: 100%;
  height: calc(var(--d-spacing-single) * 0.5);
  box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);
}

.notification-modal-button-container {
  padding: var(--d-spacing-double);
  padding-top: var(--d-spacing-single);
}`, "",{"version":3,"sources":["webpack://./src/app/notifications/component/notifications.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,WAAA;AACJ;;AAEA;EACI,oCAAA;EACA,uCAAA;EACA,oEAAA;EACA,aAAA;EACA,yBAAA;AACJ;;AAEA;EACI,aAAA;EACA,iBAAA;EACA,2CAAA;EACA,yCAAA;EACA,kBAAA;EACA,+BAAA;EACA,kFAAA;EACA,uDAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,SAAA;EACA,UAAA;EACA,UAAA;EACA,WAAA;EACA,2CAAA;EACA,0CAAA;AACJ;;AAEA;EACI,gCAAA;EACA,oCAAA;AACJ","sourcesContent":[".notification-modal-grid {\n    padding: 0;\n    height: 100%;\n    display: flex;\n    flex-flow: column;\n    width: 100%;\n}\n\n.notification-modal-cancel {\n    padding-top: var(--d-spacing-single);\n    padding-bottom: var(--d-spacing-single);\n    padding-right: calc(var(--d-spacing-single) + var(--d-spacing-half));\n    display: flex;\n    justify-content: flex-end;\n}\n\n.notification-modal-content {\n    display: flex;\n    flex-flow: column;\n    border: 2px dashed var(--ion-color-primary);\n    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);\n    border-radius: 8px;\n    color: var(--ion-color-primary);\n    padding: var(--d-spacing-double) var(--d-spacing-double) 0 var(--d-spacing-double);\n    margin: var(--d-spacing-single) var(--d-spacing-double);\n    overflow-y: scroll;\n    overflow-x: hidden;\n}\n\n.notification-modal-bottom-divider {\n    margin: 0;\n    padding: 0;\n    z-index: 2;\n    width: 100%;\n    height: calc(var(--d-spacing-single) * 0.5);\n    box-shadow: 0 -4px 4px rgba(0, 0, 0, 0.25);\n}\n\n.notification-modal-button-container {\n    padding: var(--d-spacing-double);\n    padding-top: var(--d-spacing-single);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
