import { EventEmitter, Injectable, NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloLink, DefaultOptions, from, InMemoryCache, split } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';
import * as Graphql from './graphql-generated';
import { environment } from '../environments/environment';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { LoadingService } from './loading/loading.service';
import { ErrorModalService } from './error/modal/service/error-modal.service';
import { NetworkError } from '@apollo/client/errors';
import { throwError } from 'rxjs';

export function createApollo(
    httpLink: HttpLink,
    loadingService: LoadingService,
    networkErrorService: NetworkErrorService,
): {
    cache: InMemoryCache;
    link: ApolloLink;
    defaultOptions: DefaultOptions;
} {
    // auth headers are set by GqlRequestInterceptor
    const basic = setContext(() => ({
        headers: {
            Accept: 'application/json',
        },
    }));

    const httpLinkAppBackend = ApolloLink.from([basic, httpLink.create({ uri: environment.APP_BACKEND })]);
    const wsLinkAppBackend = new GraphQLWsLink(
        createClient({
            url: environment.APP_BACKEND.replace('http', 'ws'),
        }),
    );

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        loadingService.decreaseLoadings();
        if (graphQLErrors) {
            const errorMessages = graphQLErrors.map((e) => e.message).join(' -- ');
            const errorStacks = graphQLErrors.map((e) => e.path.toString()).join(' -- ');
            const error = new Error(errorMessages);
            error.stack = errorStacks;
            error.name = `GraphQL Errors (count: ${graphQLErrors.length})`;

            console.warn(
                `[GraphQLModule errorLink] Rethrowing errors (graphQLErrors) for further error handling.`,
                error,
            );
            throwError(() => error);
        }
        if (networkError) {
            // noinspection JSIgnoredPromiseFromCall
            if (networkError.message === 'Socket closed') {
                networkErrorService.socketClosedError.emit(networkError);
            } else {
                console.warn(
                    `[GraphQLModule errorLink] Rethrowing errors (networkError) for further error handling.`,
                    networkError,
                );
                throwError(() => {
                    return { ...networkError, networkError: true };
                });
            }
        }
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        },
        wsLinkAppBackend,
        httpLinkAppBackend,
    );

    const cacheGql = new InMemoryCache({
        possibleTypes: Graphql.default.possibleTypes,
    });

    return {
        link: from([errorLink, splitLink]),
        cache: cacheGql,
        defaultOptions: {
            query: { fetchPolicy: 'no-cache' },
            watchQuery: { nextFetchPolicy: 'no-cache', fetchPolicy: 'no-cache' },
            mutate: { fetchPolicy: 'no-cache' },
        },
    };
}

@Injectable()
export class NetworkErrorService {
    socketClosedError = new EventEmitter<NetworkError>();
}

@NgModule({
    exports: [ApolloModule],
    providers: [
        NetworkErrorService,
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink, LoadingService, ErrorModalService, NetworkErrorService],
        },
    ],
})
export class GraphQLModule {}
