// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbot-feedback-container {
  padding: var(--d-spacing-double);
}

.chatbot-feedback-headline {
  font-size: 19px;
  font-weight: bold;
  color: var(--ion-color-primary);
  line-height: 130%;
  margin: 0 var(--d-spacing-half) var(--d-spacing-single) var(--d-spacing-half);
}

.chatbot-feedback-text {
  font-size: 16px;
  font-weight: 500;
  color: var(--ion-color-primary);
  line-height: 150%;
  margin: 0 var(--d-spacing-half) var(--d-spacing-triple) var(--d-spacing-half);
}`, "",{"version":3,"sources":["webpack://./src/app/chatbot/buddy/buddy-confirmation/buddy-feedback-confirmation.component.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;EACI,gCAAA;AADJ;;AAIA;ECLI,eAAA;EACA,iBAAA;EACA,+BAAA;EACA,iBAAA;EDIA,6EAAA;AAEJ;;AACA;ECHI,eAAA;EACA,gBAAA;EACA,+BAAA;EACA,iBAAA;EDEA,6EAAA;AAKJ","sourcesContent":["@import 'src/mixins.scss';\n\n.chatbot-feedback-container {\n    padding: var(--d-spacing-double);\n}\n\n.chatbot-feedback-headline {\n    @include h1-style;\n    margin: 0 var(--d-spacing-half) var(--d-spacing-single) var(--d-spacing-half);\n}\n\n.chatbot-feedback-text {\n    @include p1-style;\n    margin: 0 var(--d-spacing-half) var(--d-spacing-triple) var(--d-spacing-half);\n}\n","@mixin h1-style {\n    font-size: 19px;\n    font-weight: bold;\n    color: var(--ion-color-primary);\n    line-height: 130%;\n}\n\n@mixin p1-style {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--ion-color-primary);\n    line-height: 150%;\n}\n\n@mixin p1-berry-style {\n    font-size: 16px;\n    font-weight: 500;\n    color: var(--ion-color-secondary);\n    line-height: 150%;\n}\n\n@mixin p1-white-style {\n    font-size: 16px;\n    font-weight: 500;\n    color: white;\n    line-height: 150%;\n}\n\n@mixin p2-style {\n    font-size: 13px;\n    font-weight: 500;\n    color: var(--ion-color-primary);\n    line-height: 150%;\n}\n\n@mixin p2-berry-style {\n    font-size: 13px;\n    font-weight: 500;\n    color: var(--ion-color-secondary);\n    line-height: 150%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
