import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { getUrlSegmentsFromString } from '../utils/string-processing';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    readonly LOADING_SCREEN_DELAY = 1000;
    readonly LOADING_SCREEN_TIMEOUT = 20000;

    loading: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    overlay: HTMLIonLoadingElement | null = null;
    delay: any = null;
    timeout: any = null;

    GLOBAL_LOADING_DISABLED_ROUTE_SEGMENTS: string[][] = [['projects'], ['feed']];

    constructor(
        private loadingCtrl: LoadingController,
        private router: Router,
        private toastController: ToastController,
    ) {
        this.loading.subscribe(async (numberOfLoadings) => {
            const currentUrlSegments = getUrlSegmentsFromString(this.router.url);
            const isDisabledCurrentForUrl = this.GLOBAL_LOADING_DISABLED_ROUTE_SEGMENTS.some((disabledSegment) =>
                disabledSegment.every((segment, index) => segment === currentUrlSegments[index]),
            );
            if (numberOfLoadings == 0) {
                clearTimeout(this.delay);
                clearTimeout(this.timeout);
                await this.overlay?.dismiss();
                this.delay = null;
                this.overlay = null;
            } else if (!this.delay && !isDisabledCurrentForUrl) {
                this.delay = setTimeout(() => {
                    this.showLoading().then();
                }, this.LOADING_SCREEN_DELAY);
            }
        });
    }

    async showLoading() {
        if (!this.overlay && this.activeLoadings != 0) {
            this.overlay = await this.loadingCtrl.create({
                cssClass: 'loading-service',
                spinner: 'bubbles',
            });

            // In order to handle race conditions, we check if there are still loadings, otherwise we close the overlay
            if (this.activeLoadings == 0) {
                await this.overlay?.dismiss();
                this.overlay = null;
            }
        }

        if (this.overlay) {
            await this.overlay.present();
        }
    }

    public get activeLoadings() {
        return this.loading.value;
    }

    public decreaseLoadings() {
        this.loading.next(Math.max(0, this.activeLoadings - 1));
    }

    public increaseLoadings() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.loading.next(0);
            this.router.navigate(['feed'], { replaceUrl: true });
            this.presentToast('top');
        }, this.LOADING_SCREEN_TIMEOUT);
        this.loading.next(this.activeLoadings + 1);
    }

    private async presentToast(position: 'top' | 'middle' | 'bottom') {
        const toast = await this.toastController.create({
            message: $localize`:@@toast.loading-timeout:Missing translation`,
            duration: 3000,
            position: position,
        });
        await toast.present();
    }
}
