import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Notification, Notifications, Rule } from '../model/notifications';
import { ModalController, Platform } from '@ionic/angular';
import { NotificationsComponent } from '../component/notifications.component';
import packageJson from '../../../../package.json';
import { satisfies } from 'compare-versions';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    constructor(
        private readonly httpClient: HttpClient,
        private readonly modalController: ModalController,
        private readonly platform: Platform,
    ) {}

    private async fetchNotifications() {
        return await firstValueFrom(this.httpClient.get<Notifications>(environment.NOTIFICATIONS_URL));
    }

    /**
     * Fetches and displays notifications in a modal.
     */
    async showNotifications() {
        try {
            const res = await this.fetchNotifications();
            const matchingNotifications = this.filterNotifications(res.notifications);
            for (const matchingNotification of matchingNotifications) {
                const modal = await this.modalController.create({
                    id: `notification-${matchingNotification.id}`,
                    component: NotificationsComponent,
                    componentProps: {
                        notification: matchingNotification,
                        notificationsService: this,
                    },
                    cssClass: 'highest-prio-modal',
                });
                await modal.present();
            }
        } catch (error) {
            console.error('Failed to fetch notifications:', error);
        }
    }

    /**
     * Filters notifications based on their rules.
     * @param notifications - The list of notifications to filter.
     * @returns The filtered notifications.
     */
    filterNotifications(notifications: Notification[]): Notification[] {
        return notifications.filter((notification) => (notification.rules ?? []).some((rule) => this.matchRule(rule)));
    }

    /**
     * Gets the current platform the app is running on.
     * @returns The platform as a string ('ios', 'android', or 'web').
     */
    getPlatform(): string {
        if (this.platform.is('ios')) {
            return 'ios';
        } else if (this.platform.is('android')) {
            return 'android';
        } else {
            return 'web';
        }
    }

    getDate() {
        return new Date();
    }

    /**
     * Gets the current app version.
     * @returns The app version as a string.
     */
    getAppVersion(): string {
        return packageJson.version;
    }

    /**
     * Checks if a rule matches the current platform app version and start- and end-times.
     * @param rule - The rule to check.
     * @returns True if the rule matches, false otherwise.
     */
    matchRule(rule: Rule): boolean {
        if (!this.validateRule(rule)) return false;

        const platform = rule.plattform;
        const appVersion = rule.appVersion;
        const startDate = rule.startDate ? new Date(rule.startDate) : null;
        const endDate = rule.endDate ? new Date(rule.endDate) : null;
        const now = this.getDate();

        if (platform && platform !== this.getPlatform()) {
            return false;
        }

        if (appVersion) {
            const appVersionMatches = satisfies(this.getAppVersion(), appVersion);
            if (!appVersionMatches) {
                return false;
            }
        }

        if (startDate && now < startDate) {
            return false;
        }

        if (endDate && now > endDate) {
            return false;
        }

        return true;
    }

    /**
     * Validates a rule to ensure it contains only known keys.
     * @param rule - The rule to validate.
     * @returns True if the rule is valid, false otherwise.
     */
    validateRule(rule: Rule): boolean {
        const knownRules: (keyof Rule)[] = ['plattform', 'appVersion', 'startDate', 'endDate'] as const;
        const extraneous: string[] = [];

        for (const key in rule) {
            if (!knownRules.includes(key)) {
                extraneous.push(key);
            }
        }

        const valid = extraneous.length === 0;
        if (!valid) {
            console.error(`Rule contains extraneous keys: ${extraneous.join(', ')}`);
            return false;
        }
        return true;
    }
}
