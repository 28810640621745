import { Inject, Injectable } from '@angular/core';
import {
    AddStateValuesGQL,
    RequiredAction,
    RequiredActionsGQL,
    RequiredActionType,
    StateValueInput,
} from '../../../graphql-generated';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { DwinStateValueInputComponent } from '../../../state-value/components/state-value-input/dwin-state-value-input.component';
import { Router } from '@angular/router';
import { LoggerFactory } from '../../../kc-auth/services/logger-factory';
import { Logger } from '../../../logging/logging.service';
import { ErrorNotifyService } from '../../../error/error-notify.service';

@Injectable({ providedIn: 'root' })
export class RequiredActionsService {
    private log: Logger;
    reqActionsSub: Subscription;

    requiredActionChanges$: BehaviorSubject<RequiredAction[]> = new BehaviorSubject<RequiredAction[]>(null);

    constructor(
        private readonly requiredActions: RequiredActionsGQL,
        private readonly addStateValuesGQL: AddStateValuesGQL,
        private readonly modalCtrl: ModalController,
        private readonly router: Router,
        private errorNotifyService: ErrorNotifyService,
        @Inject('LOGGER_FACTORY') private loggerFactory: LoggerFactory,
    ) {
        this.log = this.loggerFactory.getLogger(this.constructor.name);
    }

    async checkForRequiredActions() {
        this.reqActionsSub?.unsubscribe();
        this.reqActionsSub = this.requiredActions.fetch().subscribe({
            next: async (ra) => {
                const reqActions: RequiredAction[] = ra.data.requiredActions;
                this.requiredActionChanges$.next(reqActions);
            },
            error: async (err) => {
                this.log.error(`Failed to check for requiredActions: ` + err);
                await this.errorNotifyService.showErrorToast(
                    'Verbindung zum Server fehlgeschlagen. Bitte versuche es erneut.',
                );
            },
        });
    }

    async checkIfDwinCreated() {
        this.reqActionsSub?.unsubscribe();
        this.reqActionsSub = this.requiredActions.fetch().subscribe({
            next: async (ra) => {
                const reqActions: RequiredAction[] = ra.data.requiredActions.filter(
                    (ra) => ra.requiredActionType === RequiredActionType.StateCollection,
                );
                this.requiredActionChanges$.next(reqActions);
                if (!reqActions || (reqActions && reqActions.length === 0)) {
                    this.router.navigate(['dwin-created'], { replaceUrl: true });
                }
            },
            error: async (err) => {
                this.log.error(`Failed to fetch requiredActions (dwin): ` + err);
                await this.errorNotifyService.showErrorToast(
                    'Angaben konnten nicht gespeichert werden. Bitte versuche es erneut.',
                );
            },
        });
    }

    async queryRequiredActionsIfPresent() {
        this.reqActionsSub?.unsubscribe();
        this.reqActionsSub = this.requiredActions.fetch().subscribe({
            next: async (ra) => {
                const reqActions: RequiredAction[] = ra.data.requiredActions;
                this.requiredActionChanges$.next(reqActions);
                await this.handleActions(reqActions);
            },
            error: async (err) => {
                this.log.error(`Failed to fetch requiredActions: ` + err);
                await this.errorNotifyService.showErrorToast(
                    'Startfragen konnten nicht geladen werden. Bitte versuche es erneut.',
                );
            },
        });
    }

    async handleActions(reqActions: RequiredAction[]) {
        if (!reqActions || (reqActions && reqActions.length === 0)) {
            await this.router.navigate(['dwin-created'], { replaceUrl: true });
        } else {
            let stateValues = [];
            for (const reqAction of reqActions) {
                if (reqAction.requiredActionType === RequiredActionType.StateCollection) {
                    stateValues.push(...reqAction.stateValues);
                }
            }
            if (stateValues.length > 0) {
                const modal = await this.modalCtrl.create({
                    component: DwinStateValueInputComponent,
                    componentProps: { stateValues },
                });
                modal.present();
                const selected = await modal.onDidDismiss<StateValueInput[]>();
                if (selected.role === 'confirm' || selected.role === 'save') {
                    if (selected.data?.length > 0)
                        await this.updateStateValueAndCheckRequiredActionsDwin(selected.data);
                }
            }
        }
    }

    private async updateStateValueAndCheckRequiredActionsDwin(stateValueInputs: StateValueInput[]) {
        try {
            await firstValueFrom(this.addStateValuesGQL.mutate({ stateValueInputs }));
        } catch (e) {
            this.log.error('Failed to update state value: ', e);
            await this.errorNotifyService.showErrorToast(
                'Angaben konnten nicht gespeichert werden. Bitte versuche es erneut.',
            );
        }
        await this.checkIfDwinCreated();
    }
}
