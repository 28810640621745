import { Component, Input } from '@angular/core';
import { Reference, ReferenceType } from '../../../graphql-generated';
import { ModalController } from '@ionic/angular';
import { $localize } from '@angular/localize/init';
import { BuddyMessageFeedbackFlag } from '../buddy-data-structures/buddy-message-feedback-flag';

interface FeedbackOptionListItem {
    flag: BuddyMessageFeedbackFlag;
    displayName: string;
}

@Component({
    selector: 'buddy-dislike-feedback-component',
    templateUrl: 'buddy-dislike-feedback.component.html',
    styleUrls: ['./buddy-dislike-feedback.component.scss'],
})
export class BuddyDislikeFeedbackComponent {
    @Input() title = $localize`:@@components.chatbot-dislike-feedback.report-problem:Missing translation`;
    @Input() text = $localize`:@@components.chatbot-dislike-feedback.report-problem.text:Missing translation`;

    dislikeFeedbackFlagOptions: FeedbackOptionListItem[] = [];
    selectedOption: FeedbackOptionListItem;

    references: Reference[] = [
        {
            referenceType: ReferenceType.Hint,
            description: $localize`:@@components.chatbot.dislike-feedback.hint:Missing Translation`,
        },
    ];

    constructor(private modalCtrl: ModalController) {
        this.dislikeFeedbackFlagOptions = Object.keys(BuddyMessageFeedbackFlag).map((flagOption) => {
            let option: FeedbackOptionListItem = {
                flag: flagOption as BuddyMessageFeedbackFlag,
                displayName: '',
            };
            switch (flagOption) {
                case BuddyMessageFeedbackFlag.WRONG:
                    option.displayName = $localize`:@@components.chatbot-dislike-feedback.flag.wrong:Missing translation`;
                    break;
                case BuddyMessageFeedbackFlag.HARMFUL:
                    option.displayName = $localize`:@@components.chatbot-dislike-feedback.flag.harmful:Missing translation`;
                    break;
                case BuddyMessageFeedbackFlag.OFFENSIVE:
                    option.displayName = $localize`:@@components.chatbot-dislike-feedback.flag.offensive:Missing translation`;
                    break;
                case BuddyMessageFeedbackFlag.REPETITION:
                    option.displayName = $localize`:@@components.chatbot-dislike-feedback.flag.repetition:Missing translation`;
                    break;
                case BuddyMessageFeedbackFlag.UNCLEAR:
                    option.displayName = $localize`:@@components.chatbot-dislike-feedback.flag.unclear:Missing translation`;
                    break;
                case BuddyMessageFeedbackFlag.INSUFFICIENT:
                    option.displayName = $localize`:@@components.chatbot-dislike-feedback.flag.insufficient:Missing translation`;
                    break;
                case BuddyMessageFeedbackFlag.OTHER:
                    option.displayName = $localize`:@@global.other:Missing translation`;
                    break;
            }
            return option;
        });
    }

    selectOption(flag: FeedbackOptionListItem) {
        this.selectedOption = flag;
    }

    confirmSelection() {
        if (!this.selectedOption) return;
        return this.modalCtrl.dismiss(this.selectedOption.flag, 'confirm');
    }

    cancel() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }
}
