import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DwinUiCategory } from 'src/app/common/entities/dwin-ui-category';
import { RouterExtension } from '../router/router.service';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/user';
import { Platform } from '@ionic/angular';
import { RequiredAction, RequiredActionType } from '../graphql-generated';
import { RequiredActionsService } from '../dwin/services/required-actions/required-actions.service';

interface DwinToolbarButton {
    iconPath: string;
    iconPathInactive: string;
    iconPathSelected: string;
    url: string;
    category: DwinUiCategory;
    isActive: boolean;
    isDisabled?: boolean;
    label: string;
}

@Component({
    selector: 'dwin-toolbar',
    templateUrl: './dwin-toolbar.component.html',
    styleUrls: ['./dwin-toolbar.component.scss'],
})
export class DwinToolbarComponent implements OnInit, OnDestroy {
    loggedInUserSub: Subscription;
    loggedInUser: User;
    toolbarButtons: DwinToolbarButton[] = [];

    private reqActionsSub: Subscription;
    private requiredActions: RequiredAction[] = [];

    showToolbar = true;

    private _activeCategory: DwinUiCategory;

    constructor(
        private router: RouterExtension,
        private authService: AuthService,
        private platform: Platform,
        private ngZone: NgZone,
        private reqActionsService: RequiredActionsService,
    ) {
        this.platform.keyboardDidShow.subscribe(() => {
            this.ngZone.run(() => {
                this.showToolbar = false;
            });
        });

        this.platform.keyboardDidHide.subscribe(() => {
            this.ngZone.run(() => {
                this.showToolbar = true;
            });
        });
    }

    get activeCategory(): DwinUiCategory {
        return this._activeCategory;
    }

    @Input() set activeCategory(value: DwinUiCategory) {
        for (const toolbarButton of this.toolbarButtons) toolbarButton.isActive = false;
        if (value) {
            const activeButton = this.toolbarButtons.find((tb) => tb.category === value);
            if (activeButton) activeButton.isActive = true;
        }
        if (value === DwinUiCategory.ERROR) {
            this.toolbarButtons.forEach((tb) => (tb.isDisabled = true));
        } else {
            this.updateToolbarButtonDisabledState();
        }
        this._activeCategory = value;
    }

    ngOnInit() {
        this.toolbarButtons = [
            {
                iconPath: 'assets/imgs/icon-home.svg',
                iconPathInactive: 'assets/imgs/icon-home-inactive.svg',
                iconPathSelected: 'assets/imgs/icon-home-selected.svg',
                url: '/feed',
                category: DwinUiCategory.FEED,
                isActive: false,
                isDisabled: true,
                label: $localize`:@@components.side-menu.home:Missing translation`,
            },
            {
                iconPath: 'assets/imgs/icon-dwin.svg',
                iconPathInactive: 'assets/imgs/icon-dwin-inactive.svg',
                iconPathSelected: 'assets/imgs/icon-dwin-selected.svg',
                url: '/dwin-dashboard',
                category: DwinUiCategory.DWIN,
                isActive: false,
                isDisabled: true,
                label: 'Dwin',
            },
            {
                iconPath: 'assets/imgs/icon-chatbot.svg',
                iconPathInactive: 'assets/imgs/icon-chatbot-inactive.svg',
                iconPathSelected: 'assets/imgs/icon-chatbot-selected.svg',
                url: '/chatbot',
                category: DwinUiCategory.CHATBOT,
                isActive: false,
                isDisabled: true,
                label: 'Buddy',
            },
            {
                iconPath: 'assets/imgs/icon-projects.svg',
                iconPathInactive: 'assets/imgs/icon-projects-inactive.svg',
                iconPathSelected: 'assets/imgs/icon-projects-selected.svg',
                url: '/projects',
                category: DwinUiCategory.PROJECTS,
                isActive: false,
                isDisabled: true,
                label: $localize`:@@components.side-menu.projects:Missing translation`,
            },
        ];
        this.loggedInUserSub = this.authService.loggedInUser.subscribe((user) => {
            this.loggedInUser = user;
            this.updateToolbarButtonDisabledState();
        });
        this.reqActionsSub = this.reqActionsService.requiredActionChanges$.subscribe((ra) => {
            this.requiredActions = ra;
            this.updateToolbarButtonDisabledState();
        });
    }

    updateToolbarButtonDisabledState() {
        this.toolbarButtons.forEach((tb) => {
            tb.isDisabled = !this.loggedInUser;
        });
        if (this.loggedInUser) {
            const hasLoggedInUserReqActions =
                this.requiredActions &&
                this.requiredActions.filter((ra) => ra.requiredActionType === RequiredActionType.StateCollection)
                    .length > 0;
            if (hasLoggedInUserReqActions) {
                const projectsButton = this.toolbarButtons.find((tb) => tb.category === DwinUiCategory.PROJECTS);
                if (projectsButton) {
                    projectsButton.isDisabled = true;
                    projectsButton.isActive = false;
                }
                const feedButton = this.toolbarButtons.find((tb) => tb.category === DwinUiCategory.FEED);
                if (feedButton) {
                    feedButton.isDisabled = true;
                    feedButton.isActive = false;
                }
            }
        }
    }

    ngOnDestroy() {
        this.loggedInUserSub?.unsubscribe();
        this.reqActionsSub?.unsubscribe();
    }

    async navigateToPage(url: string, isDisabled: boolean) {
        if (isDisabled) return;
        await this.router.navigate([url]);
    }
}
