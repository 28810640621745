import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { StateValueExtension } from 'src/app/state/state-value';
import { isExpired } from '../../utils/state-value.utils';

@Component({
    selector: 'dwin-numeric-state-value-input',
    templateUrl: './dwin-numeric-state-value-input.component.html',
    styleUrls: ['./dwin-numeric-state-value-input.component.scss'],
})
export class DwinNumericStateValueInputComponent {
    @Output() stateValueChange = new EventEmitter<StateValueExtension>();
    @Output() isValid = new EventEmitter<boolean>();
    @Input() initialValue: string;

    inputValueFormControl = new FormControl();
    timestamp = '';

    isStateValueExpired = false;

    private _stateValue: StateValueExtension;

    get stateValue(): StateValueExtension {
        return this._stateValue;
    }

    @Input() set stateValue(stateValue: StateValueExtension) {
        if (!this.initialValue) this.initialValue = stateValue.value;
        this._stateValue = stateValue;
        this.inputValueFormControl.clearValidators();
        this.inputValueFormControl.addValidators(this.getValidators());
        this.inputValueFormControl.setValue(stateValue.value);
        this.isValid.emit(this.inputValueFormControl.valid);
        this.updateTimestamp();
    }

    constructor(private modalCtrl: ModalController) {}

    private updateTimestamp() {
        this.isStateValueExpired = isExpired(this.stateValue.expiresAt);
        if (this.inputValueFormControl.value?.toString() === this.initialValue && this.stateValue?.timestamp) {
            this.timestamp = this.stateValue.timestamp;
        } else {
            this.timestamp = '';
        }
    }

    onInput() {
        const value = this.inputValueFormControl.value?.toString() ?? '';
        this.isValid.emit(this.inputValueFormControl.valid);
        this.stateValueChange.emit({
            state: this.stateValue.state,
            value: value,
            timestamp: this.stateValue.timestamp,
            question: this.stateValue.question ?? this.stateValue.state.defaultQuestion,
        });
        this.updateTimestamp();
    }

    getValidators(): ValidatorFn[] {
        if (this.stateValue.state.__typename !== 'StateNumeric') return [];
        const validators = [Validators.required, Validators.pattern(/^(0|[1-9]\d*)$/)];
        if (this.stateValue.state?.valueRange?.max) {
            validators.push(Validators.max(this.stateValue.state.valueRange.max));
        }
        if (this.stateValue.state?.valueRange?.min) {
            validators.push(Validators.min(this.stateValue.state.valueRange.min));
        }
        return validators;
    }

    cancel() {
        return this.modalCtrl.dismiss(null, 'cancel');
    }
}
