import { NgModule } from '@angular/core';
import { NotificationsComponent } from './component/notifications.component';
import { NotificationsService } from './service/notifications.service';
import { DwinCommonComponentsModule } from '../common/components/dwin-common-components.module';
import { IonicModule } from '@ionic/angular';
import { NgIf } from '@angular/common';
import { MarkdownModule } from '../markdown/markdown.module';

@NgModule({
    declarations: [NotificationsComponent],
    providers: [NotificationsService],
    imports: [DwinCommonComponentsModule, IonicModule, NgIf, MarkdownModule],
})
export class NotificationsModule {}
