import { Component, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { Storage } from '@ionic/storage-angular';
import { DwinUiCategory } from 'src/app/common/entities/dwin-ui-category';
import { AuthService } from 'src/app/auth/auth.service';
import { RouterExtension } from 'src/app/router/router.service';
import { User } from 'src/app/auth/user';
import { register } from 'swiper/element/bundle';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { NotificationsService } from './notifications/service/notifications.service';
import { ErrorModalService } from './error/modal/service/error-modal.service';
import { NetworkStatusService } from './services/network-status.service';

register();

interface UrlCategoryMapEntry {
    urlInfix: string;
    dwinUiCategory: DwinUiCategory;
}

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
    /**
     * Checks if the current page is the landing page. Used to disable dwin-toolbar if the user is on the landing page.
     * @returns {boolean} Returns true if the current page is the landing page, otherwise returns false.
     */
    isLandingPage(): boolean {
        return this.router.url.includes('landing-page');
    }

    urlCategoriesMap: UrlCategoryMapEntry[] = [
        { urlInfix: 'feed', dwinUiCategory: DwinUiCategory.FEED },
        { urlInfix: 'chatbot', dwinUiCategory: DwinUiCategory.CHATBOT },
        { urlInfix: 'dwin', dwinUiCategory: DwinUiCategory.DWIN },
        { urlInfix: 'landing-page', dwinUiCategory: DwinUiCategory.DWIN },
        { urlInfix: 'anonymous-flow', dwinUiCategory: DwinUiCategory.DWIN },
        { urlInfix: 'projects', dwinUiCategory: DwinUiCategory.PROJECTS },
        { urlInfix: 'profile', dwinUiCategory: DwinUiCategory.PROFILE },
        { urlInfix: 'conformity', dwinUiCategory: DwinUiCategory.CONFORMITY },
        { urlInfix: 'imprint', dwinUiCategory: DwinUiCategory.IMPRINT },
        { urlInfix: 'privacy', dwinUiCategory: DwinUiCategory.PRIVACY },
        { urlInfix: 'user-agreement', dwinUiCategory: DwinUiCategory.USER_AGREEMENT },
    ];
    activeCategory: DwinUiCategory;
    loggedInUser: User;
    networkStatus = { connected: false };

    constructor(
        private readonly router: RouterExtension,
        private readonly storage: Storage,
        private readonly authService: AuthService,
        private readonly errorModalService: ErrorModalService,
        private readonly notificationsService: NotificationsService,
        private networkStatusService: NetworkStatusService,
    ) {}

    async ngOnInit() {
        await this.networkStatusService.initNetworkStatus();
        this.networkStatus = {
            connected: this.networkStatusService.isConnected(),
        };
        if (!this.networkStatus.connected) {
            this.handleOfflineStatus();
        }
        await this.storage.create();
        this.networkStatus = await Network.getStatus();
        if (!this.networkStatus.connected) {
            this.handleOfflineStatus();
        }
        Network.addListener('networkStatusChange', (status) => {
            this.networkStatus = status;
            console.log(`networkStatusChange: `, status.connectionType, status.connected);
            this.handleOfflineStatus();
        });
        this.router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                this.activeCategory = null;
                const urlWithoutParams = e.urlAfterRedirects.split('?')[0];
                for (const categoryMapEntry of this.urlCategoriesMap) {
                    if (urlWithoutParams.includes(categoryMapEntry.urlInfix)) {
                        this.activeCategory = categoryMapEntry.dwinUiCategory;
                        break;
                    }
                }
            }
        });
        this.notificationsService.showNotifications();
        this.authService.isAuthenticated(false, true);
        await SplashScreen.hide();
        try {
            // The following line requires https://github.com/apache/cordova-plugin-screen-orientation to be installed.
            // Somehow the lock method was removed from the typescript definition file, but it seems to still work.
            // There is an issue for that: https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1615
            await (window.screen.orientation as any).lock('portrait');
        } catch (e) {
            if (e.message !== 'screen.orientation.lock() is not available on this device.') throw e;
        }
    }

    handleOfflineStatus() {
        if (!this.networkStatus.connected) {
            // noinspection JSIgnoredPromiseFromCall
            this.errorModalService.showErrorDialog({ error: new Error(`offline`) });
        } else {
            // noinspection JSIgnoredPromiseFromCall
            this.errorModalService.hide();
        }
    }
}
