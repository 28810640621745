import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { landingPageGuard } from './auth/guards/landingPageGuard';
import { buddyDisclaimerGuard, requiredActionsGuard } from './dwin/guards/required-actions.guard';
import { LoadingPage } from './loading/loading.page';
import { projectNavGuard } from './projects/guards/project-nav.guard';
import { dwinPageGuard } from './dwin/guards/dwin-page.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'landing-page',
        pathMatch: 'full',
    },
    {
        path: 'login',
        redirectTo: 'landing-page',
        pathMatch: 'full',
    },
    {
        path: 'loading',
        component: LoadingPage,
    },
    {
        path: 'feed',
        loadChildren: () => import('./feed/feed.module').then((m) => m.FeedPageModule),
        canActivate: [landingPageGuard, requiredActionsGuard],
    },
    {
        path: 'dwin',
        loadChildren: () => import('./dwin/pages/dwin-dwin.module').then((m) => m.DwinPageModule),
        canActivate: [landingPageGuard, dwinPageGuard],
        runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
    },
    {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsPageModule),
        canActivate: [landingPageGuard, requiredActionsGuard, projectNavGuard],
        canActivateChild: [landingPageGuard, requiredActionsGuard, projectNavGuard],
    },
    {
        path: 'landing-page',
        loadChildren: () => import('./landing-page/landing-page.module').then((m) => m.LandingPagePageModule),
        canActivate: [landingPageGuard],
    },
    {
        path: 'anonymous-flow',
        loadChildren: () =>
            import('./dwin/pages/anonymous-flow/anonymous-flow.module').then((m) => m.AnonymousFlowPageModule),
        canActivate: [landingPageGuard],
    },
    {
        path: 'dwin-created',
        loadChildren: () =>
            import('./dwin/pages/dwin-created/dwin-created.module').then((m) => m.DwinCreatedPageModule),
        canActivate: [landingPageGuard],
    },
    {
        path: 'profile',
        loadChildren: () => import('./profile/dwin-profile.module').then((m) => m.DwinProfileModule),
        canActivate: [landingPageGuard],
    },
    {
        path: 'chatbot',
        loadChildren: () => import('./chatbot/pages/chatbot.page.module').then((m) => m.ChatbotPageModule),
        canActivate: [landingPageGuard, buddyDisclaimerGuard],
    },
    {
        path: 'chatbot-terms-of-use',
        loadChildren: () =>
            import('./chatbot/pages/chatbot-terms-of-use/chatbot-terms-of-use.module').then(
                (m) => m.ChatbotTermsOfUseModule,
            ),
    },
    {
        path: 'legal',
        loadChildren: () => import('src/app/legal-pages/legal-pages.module').then((m) => m.LegalPagesModule),
    },
    // This needs to be placed at the very end to match all routes which have not been matched yet.
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () => import('./page-not-found/page-not-found.module').then((m) => m.PageNotFoundPageModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
