// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: false,
    AUTH_SERVER_URL: 'https://auth.distel-digital.de/auth',
    AUTH_REALM: 'dwin',
    AUTH_CLIENT: 'dwin_dev',
    APP_BACKEND: 'https://app-backend-api.dev.dwin.health/graphql',
    CHATBOT_BACKEND: 'https://buddy-api.dev.dwin.health',
    NOTIFICATIONS_URL: 'https://notifications.dwin.health/notifications-dev.json',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
