import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'buddy-feedback-confirmation',
    templateUrl: './buddy-feedback-confirmation.component.html',
    styleUrls: ['./buddy-feedback-confirmation.component.scss'],
})
export class BuddyFeedbackConfirmationComponent {
    constructor(private modalCtrl: ModalController) {}

    confirmClick() {
        this.modalCtrl.dismiss(null, 'confirm');
    }
}
