// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `markdown .markdown-text-wrapper.noTopMargin > :nth-child(1) {
  margin-top: 1px;
}
markdown .markdown-text-wrapper.noBottomMargin > :nth-child(1) {
  margin-bottom: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/markdown/markdown.component.scss"],"names":[],"mappings":"AAUY;EACI,eARG;AADnB;AAeY;EACI,kBAdM;AACtB","sourcesContent":["// WARNING: Styles defined here can leak into other components because we had to change ViewEncapsulation to none.\n// So please nest everything inside the markdown selector to avoid conflicts.\n// More info: https://angular.io/api/core/ViewEncapsulation\n$margin-top-value: 1px;\n$margin-bottom-value: 1px;\n\nmarkdown {\n    .markdown-text-wrapper {\n        &.noTopMargin {\n            // Matches only the first first-child and isn't recursive (as opposed to :first-child).\n            > :nth-child(1) {\n                margin-top: $margin-top-value;\n            }\n        }\n\n        &.noBottomMargin {\n            // Matches only the first first-child and isn't recursive (as opposed to :first-child).\n            > :nth-child(1) {\n                margin-bottom: $margin-bottom-value;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
