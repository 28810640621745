import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, finalize, map, timeout } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/loading/loading.service';
import { AuthService } from 'src/app/auth/auth.service';
import { DEFAULT_TIMEOUT } from '../app.module';
import { Logger } from '../logging/logging.service';
import { LoggerFactory } from '../kc-auth/services/logger-factory';

@Injectable()
export class GqlRequestInterceptor implements HttpInterceptor {
    urlWhiteList = [environment.APP_BACKEND];
    private log: Logger;

    constructor(
        private loadingService: LoadingService,
        private authService: AuthService,
        @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
        @Inject('LOGGER_FACTORY') private loggerFactory: LoggerFactory,
    ) {
        this.log = this.loggerFactory.getLogger(this.constructor.name);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.urlWhiteList.includes(request.url)) {
            const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
            const timeoutValueNumeric = Number(timeoutValue);

            if (!this.authService.getSession()?.tokenSet?.access_token) {
                console.error('Access token missing for gql operation: ', request?.body?.operationName);
                return;
            }
            let headers = new HttpHeaders();
            request.headers.keys().forEach((k) => {
                headers = headers.append(k, request.headers.get(k));
            });
            if (request?.body?.operationName) {
                headers = headers.append('x-gql-op', request?.body?.operationName);
            }
            const updatedHeaders = headers.set(
                'authorization',
                `Bearer ${this.authService.getSession().tokenSet.access_token}`,
            );
            const interceptedReq = request.clone({ headers: updatedHeaders });
            // Start loading
            this.loadingService.increaseLoadings();

            // Forward request
            return next
                .handle(interceptedReq)
                .pipe(
                    timeout(timeoutValueNumeric),
                    catchError((err) => {
                        this.loadingService.decreaseLoadings();
                        // noinspection JSIgnoredPromiseFromCall
                        this.log.warn('Rethrowing error for further error handling.');
                        err.networkError = true;
                        return throwError(() => err);
                    }),
                    finalize(() => {
                        this.loadingService.decreaseLoadings();
                    }),
                )
                .pipe(
                    map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                        // exclude preflight request
                        if (evt.type != 0) {
                            this.loadingService.decreaseLoadings();
                        }
                        return evt;
                    }),
                );
        }
        // skipping interception
        return next.handle(request);
    }
}
