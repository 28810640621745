import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { Logger } from '../logging/logging.service';
import { LoggerFactory } from '../kc-auth/services/logger-factory';
import { ErrorModalService } from './modal/service/error-modal.service';

@Injectable()
export class GlobalErrorService implements ErrorHandler {
    private log: Logger;
    private errorModalService: ErrorModalService;

    constructor(
        @Inject('LOGGER_FACTORY') private loggerFactory: LoggerFactory,
        private injector: Injector,
    ) {
        this.log = this.loggerFactory.getLogger(this.constructor.name);
    }

    async handleError(error: any) {
        this.errorModalService = this.injector.get(ErrorModalService);
        if (error.networkError || error.name.includes('ApolloError')) {
            this.log.error('An uncaught network error occurred. Showing error modal. ', error);
            await this.errorModalService.showErrorDialog({ error: error });
        } else {
            this.log.error('An uncaught error occurred: ', error);
        }
    }
}
